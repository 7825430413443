@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;

@layer components {
	.mr-center .markdown {
		@apply p-10 w-full min-h-screen overflow-auto flex flex-col justify-center;
	}
	.markdown{
		@apply p-10 w-full min-h-screen overflow-hidden;
// Базовые стили
		h1 { @apply text-5xl font-extrabold text-stone-800; }
		h2 { @apply text-3xl font-bold text-stone-700; }
		h3 { @apply font-bold text-stone-600; }
		pre { @apply mt-2 text-xs; }
		p { @apply text-[1rem] pb-2 indent-4; }
		p pre { @apply inline; }
		img { @apply rounded-md shadow-lg w-10/12 mx-auto my-2; }

		ul { @apply list-disc indent-1;
			li { @apply pl-0.5;
				ul{ @apply pl-5 list-inside;
				} } }

		ol { @apply list-decimal indent-1;
			li { 
				ol{ @apply pl-5 list-inside;
				} } }

// Вспомогательные
		.flex-row { @apply flex; }
		.flex-cols { @apply flex flex-col; }

// Колонки
		.cols-2 {
			@apply flex my-2 gap-2 justify-center;
			p { @apply text-[0.9rem] pb-2 indent-4; }
			h1 { @apply text-xl font-extrabold text-stone-800; }
			h2 { @apply text-lg font-extrabold text-stone-800; }
			img { @apply rounded-md w-10/12 mx-auto my-2; }
		}
		.cols-3 {
			@apply flex my-2;
			p { @apply text-[0.8rem] pb-2 indent-4; }
			h1 { @apply text-xl font-extrabold text-stone-800; }
			h2 { @apply text-lg font-extrabold text-stone-800; }
			img { @apply rounded-md w-10/12 mx-auto my-2; }
		}
		.cols-4 {
			@apply flex my-2;
			p { @apply text-[0.9rem] pb-2 indent-4; }
			h1 { @apply text-xl font-extrabold text-stone-800; }
			h2 { @apply text-lg font-extrabold text-stone-800; }
			img { @apply rounded-md w-full mx-auto; }
		}
		.col{
			@apply w-full p-3;
		}

// Две колонки
		.c2-4\/8 {
			.col:first-child { @apply w-4/12; }
			.col:last-child { @apply w-8/12; }
		}
		.c2-8\/4 {
			.col:first-child { @apply w-8/12; }
			.col:last-child { @apply w-4/12; }
		}

// Три колонки
		.c3-3\/6\/3 {
			.col:nth-child(1) { @apply w-3/12; }
			.col:nth-child(2) { @apply w-6/12; }
			.col:nth-child(3) { @apply w-3/12; }
		}
		.c3-2\/5\/5 {
			.col:nth-child(1) { @apply w-2/12; }
			.col:nth-child(2) { @apply w-5/12; }
			.col:nth-child(3) { @apply w-5/12; }
		}

// Изображения
		.img-g-2 { @apply grid grid-cols-2; }
		.img-g-3 { @apply grid grid-cols-3; }
		.img-g-4 { @apply grid grid-cols-4; }

		.img-sm img { @apply w-3/12; }
		.img-md img { @apply w-6/12; }
		.img-full img { @apply w-full; }

// Таблици
		table{
			@apply border-collapse w-full my-2;
			thead {
				tr { @apply bg-gray-200; }
				th { @apply py-1 px-4; }
			}
			tbody {
				tr:nth-child(even) { @apply bg-gray-100; }
				td { @apply py-1 px-3 text-center; }
			}
		}
		.tb-6\/12 table { @apply w-6/12; }
		.tb-9\/12 table { @apply w-9/12; }
		.tb-center table { @apply mx-auto; }
		
// Кнопки
		.btn { @apply flex font-light my-2 }
		.btn a { @apply py-2 px-5 rounded-lg w-10 text-white }
	
		.btn-blue a { @apply bg-blue-300 hover:bg-blue-500 }
		.btn-green a { @apply bg-green-300 hover:bg-green-500 }
		.btn-red a { @apply bg-red-300 hover:bg-red-500 }

// Цитата
		blockquote {
			@apply p-3 my-2 bg-gray-100 border-l-2  border-gray-500;
			p { @apply p-0 }
		}

	}
}


@tailwind utilities
